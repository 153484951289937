/* SDComponent.css */

.sd-history-and-chat-container {
  display: flex;
  flex-direction: row; /* Side-by-side layout */
  height: 100vh; /* Full viewport height */
  width: 100%; /* Full viewport width */
  align-items: stretch; /* Align items to stretch to fill the container height */
  background-color: black;
  color: white;
}

.image-history-sidebar {
  width: 25%; /* Increase width to 50% */
  background-color: #2c2c2c; /* Dark background for visibility */
  overflow-y: auto; /* Allow vertical scrolling */
  position: absolute;
  top: 110px; /* Start below the navbar */
  bottom: 0;
  left: 0;
  transition: width 0.3s ease; /* Smooth transition for resizing */
}

.sd-content-container {
  margin-left: 50%; /* Adjust margin to account for sidebar */
  width: 50%; /* Adjust width to fill remaining space */
  transition: margin-left 0.3s ease, width 0.3s ease; /* Smooth transition for resizing */
  overflow-y: auto; /* Allow vertical scrolling */
}


.sd-chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: calc(100vw - 250px); /* Adjust based on sidebar width */
  margin: 0 auto; /* Centering the main content */
  padding: 80px 20px 20px; /* Top padding considering navbar, adjust accordingly */
}

/* Individual component styles */
.sd-header-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;  /* Horizontally align items in the center */
  gap: 10px;  /* Space between the items */
  font-weight: bold;
  margin: 40px; /* Adjust the margin as needed */
}

.sd-gpt__icon {
  width: 95px; /* Icon width */
  height: 75px; /* Icon height */
  padding: 20px;
  border-radius: 8px;
}

.sd-gpt-title {
  font-size: 24px;
  margin-left: 10px;
  padding-top: 16px;
}

.sd-image-container {
  width: 100%;
  max-width: 420px; /* Adjust as needed */
  padding: 10px 20px;
  margin: 15px;
  background: linear-gradient(to right, #F81E1E, #f8801e);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sd-img {
  max-width: 100%;
  max-height: 100%;
}

.sd-no-image {
  font-size: 18px; /* Adjust as needed */
  text-align: center;
  margin-top: 10px; /* Add spacing below the image container */
}

.sd-chat-input {
  display: flex;
  justify-content: space-between; /* Align items to the left and right edges */
  align-items: center;
  margin-top: 20px; /* Adjust as needed */
  margin-bottom:50px;
  width: 100%;
}

/* Add styles for Dropdown, Textarea, and Button as needed */
.sd-nodrag-dropdown {
  /* Your styles here */
}

.sd-run-button {
  /* Your styles here */
  width: 100vw; /* Set the button to take up the full width */
  margin-top: 10px; 
}

.sd-action-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Align items to the right edge */
}

.bubble-alert {
  position: fixed;
  bottom: 20px;
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Shift left by 50% of its own width to truly center it */
  padding: 10px 20px;
  max-width: 300px; /* Control the maximum width */
  background-color: #4CAF50; /* Green background */
  color: white; /* White text color */
  text-align: center; /* Center text inside the bubble */
  border-radius: 5px; /* Rounded corners */
  z-index: 1000; /* Ensure it's on top of other content */
  animation: fadeinout 4s; /* Animation to fade in and fade out */
}

@keyframes fadeinout {
  0%, 100% { opacity: 0; }
  20%, 80% { opacity: 1; }
}
