.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #111;
  color: white;
  border-radius: 10px;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  padding-left: 1.8rem;
  padding-right: 1.8rem;
  z-index: 1001;
}
.modal-header-transcript{
  height: 1.2rem;
  background-color: #111;
  margin-bottom: 2.4rem;
  width: 100%;
}

.modal-content {
  position: relative;
  overflow-y: scroll;
  width: 100%;

}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top:20px;
}

.transcript-section{
  padding: 1.2rem;
  background-color: #000;
  overflow-y: auto;
  width: 100%;
}

.modal-header-transcript > span {
  position: fixed;
  top: 10px;
  right: 10px;
  background: none;
  border: none;

  color: white;
  cursor: pointer;
  transition: color 0.3s;
  border-width: 1px;
  padding-right: 1.2rem;
}

.modal-header-transcript > h1{
  padding: 0;
  margin: 0;
}

.close-buton{
  font-size: 36px !important; /* 50% bigger than the previous 24px */
}


.close-button:hover {
  color: #FFBD4D;
  
}

.section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 0;
  font-size: 1.2em;
  font-weight: bold;
}



.arrow {
  margin-right: 10px;
  transition: transform 0.3s;
}

.arrow.down {
  transform: rotate(0deg);
}

.arrow.right {
  transform: rotate(90deg);
}

.section-header:hover .arrow {
  color: #FFBD4D;
}

.search-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
}

.content {
  padding: 10px;
  background-color: #111;
  border-radius: 4px;
}

.timestamp-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.timestamp {
  margin-right: 10px;
}

.copy-button {
  padding: 8px 16px;
  background-color: #FFBD4D !important;
  color: black !important;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  min-width: 80px;
  text-align: center;
  margin-left: auto; 
  margin-top:10px;
  align-self: flex-end; /* This will align the button to the right */
}


.copy-button:hover{
  background-color: black !important;
  color: #FFBD4D !important;
  outline: 1px solid white !important;
  outline-width: 1px;
  outline-color: #FFBD4D !important;
}

.modal-title {
  font-size: 1.5em; /* 25% bigger than the section text */
  font-weight: bold;
  margin: 1.2rem;
}

.transcript-section > a.transcript-links{
  color: blue !important;
  text-decoration: underline !important;
  font-weight: bold !important;
}
