/* YitterComponent.css */

.yitter-container {
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  gap: 12px; /* Adjusted gap for spacing */
}

.yt-header-container {
  display: flex;
  align-items: center;
  width: 100vw;
  justify-content: center;
  font-weight: bold;
  margin: 0px;
  margin-top:150px;
}

.yt__icon {
  width: 125px;
  height: 125px;
  padding: 20px;
  border-radius: 8px;
  padding-bottom: 24px;
  object-fit: cover; /* Ensures the image doesn't get distorted */
  padding: 0;
}


.yt-title {
  font-size: 24px;
  margin-left: 0px;
  padding-top: 12px;
}

.yitter-input-button-container, .segmented-control {
  display: flex;
  gap: 10px;
}

.segmented-control button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: linear-gradient(to right, #F81E1E, #f84c1e);
  color: white;
  font-weight: bold;
  margin: 0 5px; /* Spacing between segmented control items */
  opacity: 50%;
}

.segmented-control button.active {
  box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Highlight active button */
  opacity: 100%;
}

.yitter-input, .yitter-textarea {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 50vw; /* Match the width with input box */
  color: black;
}

.yitter-button {
  padding: 12px 20px; /* Increased padding */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: linear-gradient(to right, #F81E1E, #f84c1e);
  color: white;
  font-weight: bold;
  margin: 20px;
  font-size: 16px; /* Adjusted font size for better readability */
}

.yitter-button:hover {
  opacity: 0.9;
}

.yitter-button:active {
  opacity: 0.8;
}

.yitter-textarea {
  min-height: 150px; /* Adjust height as needed */
}

.custom-prompt-and-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align children to the start */
  width: 50vw; /* Match the width with the input box for alignment */
  margin: 0 auto; /* Center the wrapper in the parent container if needed */
}

.custom-prompt-header {
  cursor: pointer;
  display: flex;
  align-items: center; /* Correct the alignment property */
}

.arrow {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin-right: 5px;
  transition: transform 0.3s ease;
}

.arrow.down {
  border-top: 5px solid white; /* Adjust the color as needed */
}

.arrow.right {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid white; /* Adjust the color as needed */
  transform: rotate(0);
}

.feeds-container {
  margin-top: 5px;
  padding: 15px; /* Increased padding */
  background-color: #333; /* Dark grey background */
  border: 1px solid #444; /* Slightly lighter grey for the border */
  width: 80vw; /* Width as a percentage of the viewport width */
  max-width: 1024px; /* Ensure it doesn't exceed the viewport width */
  box-sizing: border-box; /* Include padding and border in the width */
  border-radius: 8px; /* Slightly rounded corners */
  overflow-y: scroll; /* Always show vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  height: 400px; /* Fixed height for scroll area */
}

.feed-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: white; /* Set text color to white */
  border-bottom: 1px solid #444; /* Line between items */
  padding-bottom: 10px; /* Add space below each item */
}

.feed-item:last-child {
  border-bottom: none; /* Remove border from the last item */
}

.feed-image {
  width: 10vw; /* Image width as a percentage of the viewport width */
  height: 10vw; /* Image height as a percentage of the viewport width */
  margin-right: 15px; /* Increased margin */
  border-radius: 4px; /* Slightly rounded corners */
}

.feed-info {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.feed-podcast-name, .feed-creator, .feed-title {
  margin: 0;
  font-weight: bold;
}

.feed-podcast-name {
  font-size: 18px; /* Example size */
  font-weight: bold !important;
}

.feed-creator {
  font-style: italic; /* Style creator name */
  display: none; /* Hide the creator label */
}

.feed-title {
  font-size: 16px;
  margin-top: 5px; /* Space above the title */
}

.feed-description {
  margin-top: 5px; /* Space above the description */
  margin-bottom: 20px; /* Add margin to create space between the description and the buttons */
  color: white; /* Set text color to white */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis */
  white-space: normal; /* Allow text to wrap */
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to 3 lines of text */
  -webkit-box-orient: vertical;
  font-size: 14px;
}

.select-podcast-button {
  padding: 15px 20px; /* Increased padding */
  border: 2px solid rgb(255, 165, 0); /* Border with the specified color */
  border-radius: 4px;
  cursor: pointer;
  background: none; /* No background */
  color: white;
  font-weight: bold;
  font-size: 16px; /* Increased font size for better readability */
  transition: background 0.3s ease; /* Smooth transition for hover effect */
}

.select-podcast-button:hover {
  background: rgb(255, 165, 0); /* Fill background on hover */
  color: black; /* Change text color on hover */
}

.yitter-podcasts-empty {
  margin-top: 5px;
  padding: 15px; /* Increased padding */
  background-color: #333; /* Dark grey background */
  border: 1px solid #444; /* Slightly lighter grey for the border */
  text-align: center; /* Center text */
  color: white; /* Set text color to white */
  min-width: 60vw; /* Minimum width as a percentage of the viewport width */
  box-sizing: border-box; /* Include padding and border in the width */
  border-radius: 8px; /* Slightly rounded corners */
}

::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #333; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #fff; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners for the scrollbar thumb */
}

.scrollbar {
  scrollbar-color: #fff #333; /* For Firefox */
  scrollbar-width: thin; /* Thin scrollbar for Firefox */
}

/* FeedDataModal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #333;
  color: white;
  border-radius: 8px;
  width: 80vw;
  max-width: 900px;
  overflow: hidden;
  position: relative;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
}

.modal-header {
  padding: 20px;
  border-bottom: 1px solid #444;
  background-color: #444;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

/* Original image styling for general use */
.feed-image {
  width: 10vw; /* Image width as a percentage of the viewport width */
  height: 10vw; /* Image height as a percentage of the viewport width */
  margin-right: 15px; /* Increased margin */
  border-radius: 8px; /* Rounded corners */
}

/* New class for images in the modal with YouTube thumbnail proportions */
.modal-image {
  width: 90px; /* Width matching typical YouTube thumbnail aspect ratio */
  height: 90px; /* Height matching typical YouTube thumbnail aspect ratio */
  margin-right: 15px; /* Margin between image and text */
  border-radius: 4px; /* Slightly rounded corners */
}

.segmented-control {
  display: flex;
  margin-bottom: 10px;
}

.segmented-control button {
  background: none;
  border: none;
  color: white;
  margin-right: 10px;
  padding: 10px;
  cursor: pointer;
}

.segmented-control button.active {
  border-bottom: 2px solid #f8801e;
}

.custom-prompt-header {
  cursor: pointer;
}

.yitter-textarea {
  width: 100%;
  height: 100px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #555;
  background-color: #444;
  color: white;
}

.yitter-button {
  padding: 12px 20px; /* Increased padding */
  border: 2px solid rgb(255, 165, 0); /* Border with the specified color */
  border-radius: 4px;
  cursor: pointer;
  background: none; /* No background */
  color: white;
  font-weight: bold;
  margin: 20px;
  font-size: 16px; /* Adjusted font size for better readability */
  transition: background 0.3s ease; /* Smooth transition for hover effect */
}

.yitter-button:hover {
  background: rgb(255, 165, 0); /* Fill background on hover */
  color: black; /* Change text color on hover */
}

.episodes-container {
  padding: 20px;
  overflow-y: auto;
  flex: 1;
}

.episodes-list {
  display: flex;
  flex-direction: column;
}

/* FeedDataModal */
.episode-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #444;
  padding-bottom: 10px;
  position: relative; /* Position relative to place button */
}

.episode-info {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.episode-details {
  margin-right: 140px; /* Increased space for the button to avoid clash */
}

.episode-title {
  margin: 0;
  font-size: 1.2em;
}

.episode-creator {
  margin: 0;
  font-style: italic; /* Style creator name */
}

.episode-date {
  margin: 5px 0;
}

.episode-description {
  margin: 10px 0 20px 0; /* Add margin to create space between the description and the buttons */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
}

.episode-button-container {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Space between buttons */
  position: absolute;
  right: 0;
}

.episode-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: #fc883d; /* Use the orange color for the button */
  color: white; /* White text color for contrast */
  font-weight: bold;
  transition: background 0.3s ease, color 0.3s ease;
}

.episode-button img {
  width: 30px;
  height: 30px;
  margin-right: 10px; /* Space between image and text */
}

.episode-button:hover {
  background: #111; /* Invert colors on hover */
  color: #fc883d;
  border-width: 2px;
  border-color: #fc883d;
}

.listen-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: black; /* Black background for the button */
  color: #ffbd4d; /* Yellow text color */
  font-weight: bold;
  transition: background 0.3s ease, color 0.3s ease;
}

.listen-button img {
  width: 30px;
  height: 30px;
  margin-right: 10px; /* Space between image and text */
}

.listen-button:hover {
  background: #ffbd4d; /* Invert colors on hover */
  color: black;
}

.episode-image {
  width: 100%;
  max-width: 200px; /* Set a maximum width as per your design requirements */
  aspect-ratio: 4 / 3;
  object-fit: contain;
  margin-right: 15px;
  border-radius: 6px; /* Slightly rounded corners */
}

.modal-header {
  padding: 20px;
  border-bottom: 1px solid #444;
  background-color: #444;
  position: relative; /* Ensure the button can be positioned absolutely */
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.close-button:hover {
  background: #FFBD4D; /* Change color on hover */
}

@media (max-width: 768px) {
  .episode-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .episode-button-container {
    flex-direction: row;
    position: static;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  }

  .episode-info {
    width: 100%;
  }

  .episode-details {
    margin-right: 0;
  }

  .episode-description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


@media (min-width: 1024px) {
  .feed-item {
    height: auto; /* Allow dynamic height on larger screens */
    align-items: flex-start;
  }

  .feed-description {
    max-width: 60%; /* Take up more space on wider screens */
    font-size: 20px; /* Increase font size on desktop */
  }

  .feed-podcast-name {
    font-size: 20px; /* Larger font for desktop */
  }

  .feed-title {
    font-size: 24px;
  }

  .feed-image {
    width: 100px; /* Fixed width on larger screens */
    height: 100px;
  }
}
