/* YitterComponent.css */

.yitter-container {
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  gap: 20px; /* Adjusted gap for spacing */
}

.yt-header-container {
  display: flex;
  align-items: center;
  width:100vw;
  justify-content: center;
  font-weight:bold;
  margin: 20px;
}

.yt__icon {
  width: 125px;
  height: 125px;
  padding: 20px;
  border-radius: 8px;
  padding-bottom:24px;
}

.yt-title {
  font-size: 24px;
  margin-left: 0px;
  padding-left:18px;
  padding-top:12px;
}

.yitter-input-button-container, .segmented-control {
  display: flex;
  gap: 10px;
}

.segmented-control button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: linear-gradient(to right, #F81E1E, #f84c1e);
  color: white;
  font-weight: bold;
  margin: 0 5px; /* Spacing between segmented control items */
  opacity: 50%
}

.segmented-control button.active {
  box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Highlight active button */
  opacity: 100%
}

.yitter-input, .yitter-textarea {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 50vw; /* Match the width with input box */
  margin-bottom: 5px;
  color: black;
}

.yitter-button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: linear-gradient(to right, #F81E1E, #f84c1e);
  color: white;
  font-weight: bold;
}

.yitter-button:hover {
  opacity: 0.9;
}

.yitter-button:active {
  opacity: 0.8;
}

.yitter-textarea {
  min-height: 150px; /* Adjust height as needed */
}

.custom-prompt-header {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
}

.arrow {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin-right: 5px;
  transition: transform 0.3s ease;
}

.arrow.down {
  border-top: 5px solid white; /* Adjust the color as needed */
}

.arrow.right {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid white; /* Adjust the color as needed */
  transform: rotate(0);
}


.stream-container {
  display: flex;
  flex-direction: row; /* Aligns children (video and log capture) in a row */
  width: 100%; /* Takes full width of the parent */
  @media (max-width: 900px) {
    flex-direction: column;
  }
}



.video-container {
  flex: 2; /* Takes up 2 parts of the available space, making it twice as large as the log container */
  padding: 10px; /* Adds some space around the video player */
}

.content-container {
  flex: 1; /* Takes up the remaining space */
  padding: 10px;
  display: flex;
  flex-direction: column; /* Stacks tabs and content vertically */
}

.log-container {
  flex: 1; /* Takes up 1 part of the available space, making it smaller than the video container */
  padding: 10px; /* Consistent padding with video container */
  height: full; /* Ensure it takes the full height if needed */
  width: 100%; /* This might need adjustment based on your layout specifics */
}


.tool-tab-button {
  display: block; /* Makes the link block level, filling the entire grid cell */
  text-decoration: none; /* Removes underline from links */
  color: inherit; /* Inherits text color from parent */
  width: 100%; /* Ensures it takes up all available width */
}

.tool-tab-button:hover {
  background-color: #FFA500; /* Changes background on hover */
  color: black; /* Changes text color on hover */
}

.bubble-alert {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #FFBD4D;
  color: #000;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 16px;
  animation: fadeInOut 5s ease-in-out;
  z-index: 1000;
}

.truncate {
  max-width: 150px; /* Adjust width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
