/* CheckoutFormComponent.css */

.mainWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.checkout-button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: orangered !important;
    color: white;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Add box shadow */
    transition: box-shadow 0.3s, transform 0.3s; /* Add transition effects */
  }
  
  .checkout-button:hover {
    opacity: 0.9;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.7); /* Adjust box shadow on hover */
    transform: translateY(-2px); /* Move button slightly up on hover */
  }
  
  .checkout-button:active {
    opacity: 0.8;
  }

  .flex-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .image-box{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 515px;
    background-color: black;
    height: 100%;
    border-color: #F81E1E;
    border-width: 5px;
    width: 100%
  }

  .checkout-box{
    width: 100%;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 1000px) {
    .flex-container {
      flex-direction: row; /* Switch to row layout when screen width is at least 768px */
    }
    .image-box{
        height: 843px;
        width: 25%;
    }
  }