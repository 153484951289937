.pricing-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 2rem;
  background-color: #1a1b1e;
  color: white;
}

.pricing-card {
  background-color: #25262b;
  border-radius: 8px;
  padding: 2rem;
  width: 300px;
  text-align: center;
  transition: all 0.3s ease;
}

.pricing-card.highlighted {
  border: 2px solid orangered;
}

.plan-name {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.plan-description {
  color: #a1a1aa;
  margin-bottom: 1rem;
}

.price {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.currency, .period {
  font-size: 1rem;
  vertical-align: super;
}

.cta-button {
  background-color: orangered;
  color: #1a1b1e;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #4ac8e0;
}

.feature-list {
  list-style-type: none;
  padding: 0;
  margin-top: 2rem;
  text-align: left;
}

.feature-item {
  margin-bottom: 0.5rem;
}

.checkmark {
  color: orangered;
  margin-right: 0.5rem;
}