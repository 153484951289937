@media (max-width: 768px) {
  .carousel-slide {
    height: auto;
    padding: 10px;
    position: relative;
    z-index: 10;
  }

  .carousel-image {
    width: 80px;
  }

  .carousel-title {
    font-size: 20px;
  }

  .carousel-description {
    font-size: 14px;
  }
}

/* Custom styles for Slick slider dots */
.slick-dots {
  bottom: -40px; /* Adjust this value to position the dots */
}

.slick-dots li button:before {
  font-size: 12px;
  color: white;
  opacity: 0.5;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: white;
}