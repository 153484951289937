.custom-node {
  position: relative;
  background: linear-gradient(to right, #F81E1E, #f8801e);
  background-color: transparent;
  color:white;
  background-color: transparent !important;
  border-radius: 10px !important;
  overflow: hidden !important;
  font-size:12px;
}

.custom-node__icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px; /* adjust as needed */
  height: 20px; /* adjust as needed */
  padding-right: 4px;
  padding-top: 4px;
}

.custom_node__dropdown{
  color:black;
  font-weight: 700;
}

.custom-node__icon img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  margin-top:4px;
}

.custom-node__body{
  background-color:transparent;
}

.react-flow__node {
  background-color: transparent !important;
}