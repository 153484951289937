.sd-history-and-chat-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  padding-left: 20px;
  align-items: stretch;
}

.image-history-sidebar {
  width: 30%; /* Adjust width to 30% */
  background-color: #2c2c2c;
  overflow-y: auto;
  position: absolute;
  top: 110px;
  bottom: 0;
  left: 0;
  transition: width 0.3s ease;
}

.sd-content-container {
  margin-left: 30%; /* Set margin left to 30% */
  width: 70%; /* Set width to 70% */
  transition: margin-left 0.3s ease, width 0.3s ease;
  overflow-y: auto;
}

.sd-chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: calc(100vw - 250px);
  margin: 0 auto;
  padding: 80px 20px 20px;
}

/* Styles for image history items */
.image-history-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #444;
  width: 100%; /* Ensure each item uses full width of sidebar */
}

.image-history-thumbnail {
  width: 30%;
  height: auto;
  border-radius: 8px;
}

.image-history-info {
  width: 65%;
  padding-left: 10px;
}



.bubble-alert {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  max-width: 300px;
  background-color: #4CAF50;
  color: white;
  text-align: center;
  border-radius: 5px;
  z-index: 1000;
  animation: fadeinout 4s;
}

@keyframes fadeinout {
  0%, 100% { opacity: 0; }
  20%, 80% { opacity: 1; }
}

.copy-button {
  padding: 8px 16px;
  background-color: #FFBD4D;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  width: 120px;  /* Set a fixed width */
  min-width: 120px;  /* Ensure the width is applied */
  display: inline-block;  /* Change display property to allow width */
}
.copy-button:hover {
  background-color: black;
  color: #FFBD4D;
}