.log-capture-component {
  background-color: black;
  color: #FFBD4D;
  font-family: 'Courier New', Courier, monospace;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  padding: 20px;
  box-shadow: inset 0 0 10px #000000;
}

.log-capture-component:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: repeating-linear-gradient(
    to bottom,
    transparent,
    transparent 1.98px,
    rgba(255, 255, 255, 0.03) 1.98px,
    rgba(255, 255, 255, 0.03) 2px
  );
  pointer-events: none;
}

.log-capture-component ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.log-capture-component li {
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.7);
  word-wrap: break-word;
  margin-bottom: 4px;
  margin-right:8px;
}

.react-flow__minimap{
  opacity:0%;
  width:1px;
}

.react-flow__attribution{
  opacity:0%;
}

.transcript-area {
  max-height: 400px; /* Adjust this value based on your design needs */
  overflow-y: auto; /* Enables vertical scrolling */
  padding: 10px;
  margin: 10px;
  background-color: black;
  color: #FFBD4D;
  font-family: 'Courier New', Courier, monospace;
  border-radius: 8px; /* Optional: adds rounded corners */
  border: 1px solid #201201; /* Optional: adds a border around the text area */
}

.transcript-text {
  cursor: pointer;
  padding: 1px; /* Add padding for better readability */
  margin-bottom: 2px; /* Space between paragraphs */
  font-weight: normal;
  transition: all 0.1s ease;
}

.transcript-text:hover {
  font-weight: bolder;
}

.transcript-text.active {
  text-decoration: underline;
  font-weight: bold;
}

.transcript-text.highlighted {
  background-color: #FFBD4D;
  color: black; /* Highlight background */
  font-weight: bold; /* Make text bold */
}
.button-style {
  cursor: pointer;
  padding: 10px 20px;
  background-color: black;
  border-color: white;
  border-width: 3px;
  color: white;
  font-weight: bold;
  border-radius: 8px;
}

.button-container {
  display: flex;
  justify-content: space-between; /* Ensures buttons are spread out */
  align-items: center;
  padding: 10px;
}

.button-container.nowrap {
  white-space: nowrap;
}

.button-container button:disabled {
  cursor: not-allowed;      /* Change cursor to indicate non-clickable */
  opacity: 0.6;             /* Reduce opacity */
}

.bubble-alert {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #FFBD4D;
  color: #000;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 16px;
  animation: fadeInOut 5s ease-in-out;
  z-index: 1000;
}