/* Episode Item Container */
.episode-item-table-row {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  margin: 8px 16px; /* Reduced left margin */
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  overflow: hidden; /* Ensure content stays within bounds */
}

/* Episode Image */
.episode-item-table-row-image {
  width: 140px; /* Reduced image size for mobile */
  height: auto;
  object-fit: cover;
  flex-shrink: 0;
  border-radius: 8px;
  margin-left: 8px;
  margin-right: 12px;
}

/* Episode Info Container */
.episode-item-table-row-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  gap: 8px;
  overflow: hidden;
}

/* Episode Details Text Block */
.episode-item-table-row-details {
  flex: 1;
  overflow: hidden;
  margin-right: 12px;
}

.episode-item-table-row-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  margin-bottom: 8px;
}

.episode-item-table-row-creator,
.episode-item-table-row-date {
  margin-bottom: 8px;
}

.episode-item-table-row-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-width: 100%;
  font-weight: normal;
  margin-bottom: 8px;
}

/* Button Container */
.episode-item-table-row-button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 120px;
  flex-shrink: 0;
}

/* Select Button Styles */
.episode-item-table-row-select-button,
.episode-item-table-row-listen-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  min-width: 100px;
  background-color: black;
  color: white;
  border-radius: 9999px;
  font-weight: bold !important;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.2s ease;
}

.episode-item-table-row-select-button:hover {
  background-color: black;
  color: white;
  border-color: white;
}

.episode-item-table-row-listen-button:hover {
  background-color: black;
  color: white;
  border-color: #FFD700;
}

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
  .episode-item-table-row {
    flex-direction: column;
    padding: 12px;
    margin: 8px;
    align-items: center; /* Center align for mobile */
  }

  .episode-item-table-row-image {
    width: 100px; /* Smaller, centered image */
    max-width: 100%;
    margin-bottom: 12px;
  }

  .episode-item-table-row-info {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
    gap: 8px;
  }

  .episode-item-table-row-details {
    width: 100%;
    text-align: center; /* Center-align text for a balanced look */
    margin-bottom: 12px;
  }

  .episode-item-table-row-button-container {
    width: 100%;
    align-items: center; /* Center align button container */
    margin-right: 0;
  }

  .episode-item-table-row-select-button,
  .episode-item-table-row-listen-button {
    width: 100%;
    max-width: 150px;
    font-weight: bold !important;
  }
}
