@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@700&display=swap');

.intro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto; /* Changed from min-height */
    box-sizing: border-box;
    pointer-events: auto;
    overflow-y: auto; /* Ensure this remains */
  }

  .content-wrapper {
    width: 100%;
    max-width: 1200px; /* Adjust this value to match your design */
    margin: 0 auto;
    padding: 20px 0;
    box-sizing: border-box;
}

.intro-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.logo {
  max-width: 104px;
  height: auto;
  margin-bottom: 16px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}

.title-logo {
  max-width: 325px;
  height: auto;
  margin-bottom: 20px;
}

.subtitle-preview-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.subtitle {
  margin-bottom: 40px;
  text-align: center;
}

.preview-container {
    width: 100%;
    max-width: 100%;
    overflow: visible;
    margin-top: calc(-100px + 2%);
    pointer-events: auto;
    aspect-ratio: 1780 / 1260; /* Adjust this ratio to match your image's aspect ratio */
  }

.logic-app-preview {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.apps-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}


.section-header, .all-apps-header {
  font-family: "League Spartan", sans-serif;
  font-size: 40px;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: -0.02em;
  padding-left: 0;
  padding-right: 0;
  align-self: flex-start;
  width: 100%;
}

.all-apps-header {
  margin-top: 40px;
}

@media (max-width: 768px) {
  .content-wrapper {
    padding: 10px;
  }

  .logo {
    max-width: 80px;
  }

  .title-logo {
    max-width: 250px;
  }

  .subtitle {
    margin-bottom: 30px;
  }

  .preview-container {
    margin-top: -15px;
  }
}