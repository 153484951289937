.blueButton {
  background-color: blue !important;
}

/* Apply linear gradient background to the submit button */
.submit-button {
  cursor: pointer;
  background: linear-gradient(to right, #f8801e, #f84c1e);
  color: white;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Add box shadow */
  transition: box-shadow 0.3s, transform 0.3s; /* Add transition effects */
}

html {
  --bc-color-brand: #000; 
  --bc-color-brand-dark: #000; 
  --bc-color-brand-button-text: #fff; /* override text color for primary button. Normally this is based on the luminance of the brand color */
  --bc-color-brand-button-text-dark: #fff;
}