.quotes-display-container {
  background-color: black;
  color: #FFBD4D;
  font-family: 'Courier New', Courier, monospace;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  padding: 20px;
  box-shadow: inset 0 0 10px #000000;
}

.quotes-display-container ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.quotes-display-container li {
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.7);
  word-wrap: break-word;
  margin-bottom: 4px;
  margin-right: 8px;
  border: 1px solid #201201;
  padding: 10px;
  border-radius: 8px;
}

.quotes-display-container input {
  width: 50px;
  margin-right: 10px;
  background-color: black;
  color: #FFBD4D;
  border: 1px solid #FFA500;
  border-radius: 4px;
}

.button-style {
  padding: 15px 15px;
  width: 180px;
  border: 2px solid #FFA500;
  border-radius: 4px;
  cursor: pointer;
  background: transparent;
  color: white;
  font-weight: bold;
}

.button-style:hover {
  background: #FFA500;
  color: black;
}


.quote-text {
  cursor: pointer;
  text-decoration: none;
  color: #FFBD4D;
}

.quote-text:hover {
  text-decoration: underline;
}

