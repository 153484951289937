.ctsm-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #111;
    color: white;
    border-radius: 10px;
    padding: 20px;
    z-index: 1000;
    width: 80%;
    max-height: 80%;
    overflow-y: auto;
  }
  
  .ctsm-modal-content {
    position: relative;
    width: 100%;
  }
  
  .ctsm-close-button {
    position: fixed;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 36px;
    color: white;
    cursor: pointer;
    transition: color 0.3s;
    border-width: 1px;
  }
  
  .ctsm-close-button:hover {
    color: #FFBD4D;
  }
  
  .ctsm-section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 10px 0;
    font-size: 1.2em;
    font-weight: bold;
  }
  
  .ctsm-arrow {
    margin-right: 10px;
    transition: transform 0.3s;
  }
  
  .ctsm-arrow.down {
    transform: rotate(0deg);
  }
  
  .ctsm-arrow.right {
    transform: rotate(90deg);
  }
  
  .ctsm-section-header:hover .ctsm-arrow {
    color: #f8801e;
  }
  
  .ctsm-search-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 4px;
  }
  
  .ctsm-content {
    padding: 10px;
    background-color: #222;
    border-radius: 4px;
  }
  
  .ctsm-timestamp-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .ctsm-timestamp {
    margin-right: 10px;
  }
  
  .ctsm-copy-button {
    padding: 8px 16px;
    background-color: #FFBD4D;
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    margin-left: 10px;
    white-space: nowrap;
  }
  
  .ctsm-copy-button:hover {
    background-color: black;
    color: #FFBD4D;
    outline: 1px solid white;
    outline-width: 1px;
    outline-color: #FFBD4D;
  }
  
  .ctsm-modal-title {
    font-size: 1.5em;
    font-weight: bold;
  }
  