.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: linear-gradient(to right, #f8801e, #f84c1e);
  color: white;
  border: none;
  padding: 12px; /* Added padding to ensure enough space around the icon */
  border-radius: 50%; /* Make it a circular button */
  cursor: pointer;
  z-index: 9999;
  transition: opacity 0.3s ease, transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-to-top-button:hover {
  opacity: 0.9;
  transform: scale(1.05);
}


.whisper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  padding-top:calc(120px + 5vh);
  font-weight: 700;
}

.whisper-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.whisper__icon {
  width: 80px;
  height: 80px;
  margin-bottom: 4px;
}

.whisper__icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.whisper-input-button-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
}

.whisper-input {
  padding: 10px 15px;
  border-radius: 20px;
  border: 2px solid #FFB040;
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  background-color: #000000;
  color: #FFFFFF;
  font-size: 16px;
  outline: none;
  box-sizing: border-box;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.whisper-button {
  padding: 15px 20px;
  border: 2px solid rgb(255, 165, 0);
  border-radius: 4px;
  cursor: pointer;
  background: none;
  color: white;
  font-weight: bold;
  font-size: 16px;
  transition: background 0.3s ease;
  width: 100%;
}

.whisper-button:hover {
  background: rgb(255, 165, 0);
  color: black;
}

/* Media query for larger screens */
@media (min-width: 768px) {
  .whisper-header-container {
    flex-direction: row;
    justify-content: center;
  }

  .whisper__icon {
    width: 125px;
    height: 125px;
    margin-right: 20px;
    margin-bottom: 0;
  }

  .whisper-title {
    font-size: 24px;
    text-align: left;
  }

  .button-container {
    flex-direction: row;
  }

  .whisper-button {
    width: auto;
  }
}

/* Styling for the search bar */
.jamie-search-bar {
  width: 100%;
  padding: 12px 16px;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  border: none;
  font-size: 16px;
  outline: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.jamie-search-bar::placeholder {
  color: black;
  opacity: 0.6;
}

.jamie-search-bar:focus {
  background-color: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
}


.select-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 8px 16px;
  min-width: 100px;
  background-color: black;
  color: white;
  border-radius: 9999px;
  font-weight: bolder;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.2s ease;
  text-align: center;
}

.select-button:hover {
  background-color: black;
  color: white;
  border-color: white;
}