:root {
  --arrow-color: white;
}

.prompt-options-container {
  width: 100%;
  overflow: hidden;
}

.segmented-control-wrapper {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.segmented-control {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
}

.segmented-control::-webkit-scrollbar {
  display: none;
}

.segmented-control button {
  flex: 0 0 auto;
  padding: 8px 16px;
  background-color: #333;
  color: white;
  border: 1px solid #555;
  border-right: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.segmented-control button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.segmented-control button:last-child {
  border-right: 1px solid #555;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.segmented-control button.active {
  background-color: #555;
}

.scroll-button {
  position: absolute;
  top: 0;
  bottom: 10px;
  width: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.scroll-button.left {
  left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.scroll-button.right {
  right: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  display: inline-block;
  margin-right: 5px;
}

.arrow.right {
  border-left: 5px solid var(--arrow-color);
}

.arrow.down {
  border-top: 5px solid var(--arrow-color);
}

.prompt-and-run-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}

.custom-prompt-textarea {
  width: 100%;
  min-height: 100px;
  padding: 8px;
  background-color: #333;
  color: white;
  border: 1px solid #555;
  border-radius: 4px;
}

.ctsm-run-button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.ctsm-run-button {
  width: 100px;
  padding: 8px 16px;
  background-color: #FFB040;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, color 0.3s;
}

.ctsm-run-button:hover {
  background-color: black;
  color: #FFB040;
  outline: 1px solid #FFB040;
}

.jamie-context .arrow.right {
  border-left: 5px solid black;
}

.jamie-context .arrow.down {
  border-top: 5px solid black;
}

.prompt-options-textarea {
  width: 100%;
  height: 100px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #555;
  background-color: black;
  color: white;
  min-height: 150px;
}

.additional-context-textarea {
  width: 100%;
  height: 75px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #555;
  background-color: black;
  color: white;
  min-height: 50px;
}