/* CHADbotComponent.css */

.chad-container {
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  gap: 20px; /* Adjusted gap for spacing */
}

.chad-header-container {
  display: flex;
  align-items: center;
  width:100vw;
  justify-content: center;
  font-weight:bold;
  margin-bottom: 0px;
  margin-top: 100px;
}

.chad__icon {
  width: 125px;
  height: 125px;
  padding: 20px;
  border-radius: 16px;
  padding-bottom:24px;
}

.chad-title {
  font-size: 24px;
  margin-left: 0px;
  padding-top:12px;
}

.chad-input-button-container, .segmented-control {
  display: flex;
  gap: 10px;
}

.chad-input-group {
  display: flex;
  align-items: center; /* This ensures vertical centering */
  border: 1px solid #ccc; /* Optional: adds a border around the group */
  border-radius: 4px; /* Optional: rounds the corners of the border */
}

.chad-extensions-button {
  margin-left:-40px;
  border: 1px solid #bbb;
  width: 30px;
  height:30px;
}

.chad-input, .chad-textarea {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 50vw; /* Match the width with input box */
  color: black;
}

.chad-link-input {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: black;
  padding-left:20px;
  padding-right:20px;
}

.chad-checkbox{
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  width: 20vw; /* Match the width with input box */
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chad-contact-info{
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  min-width: 20vw; /* Match the width with input box */
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.chad-subscription{
  padding: 10px;
  border: 1px solid #ccc;
  min-width: 300px; /* Match the width with input box */
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  margin-bottom: 10px;
  max-width: 300px;
}

.chad-button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: linear-gradient(to right, #F81E1E, #f84c1e);
  color: white;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Add box shadow */
  transition: box-shadow 0.3s, transform 0.3s; /* Add transition effects */
}

.chad-button:hover {
  opacity: 0.9;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.7); /* Adjust box shadow on hover */
  transform: translateY(-2px); /* Move button slightly up on hover */
}

.chad-button:active {
  opacity: 0.8;
}

.chad-textarea {
  min-height: 150px; /* Adjust height as needed */
}

.chad-link{
    font-family: 'Arial, sans-serif';
    font-size: 30px;
    margin-bottom: 10px;
    background-color: black;
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
